
<body>
  <div class="jumbotron text-center" style="background-color: black; font-family:'Kanit';">
    <h1 style="color: white;">Quinn's Games</h1>
    <p style="color: gray;">
      The official website for Quinn Patrick's indie games!
    </p>
    <div class="container">
    <app-navbar></app-navbar>
  </div>

  </div>
  <div class="container">
  <div class="row">
    

    <div class="col-lg-9">
      
        <router-outlet></router-outlet>
      
    </div>
    <div class="col-lg-3">      
        <a class="twitter-timeline" data-height="1000" data-width = "400" href="https://twitter.com/Daisuke9091?ref_src=twsrc%5Etfw">Tweets by Quinn Patrick</a>
    </div>
  </div>
  </div>
</body>