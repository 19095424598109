
    <h1>Super Survival Ball</h1>


    <div class = "row">
        <div class="col-sm-8">
            <h3>
                In the dystopian future of 201X, rack up massive points by bouncing balls in outer space and keeping your chain alive!
                
                It is truly the ultimate ball game!!
                <br/><br/>
                
            </h3>
        </div>
        <div class="col-sm-4">  
            <img src="../assets/titleScreenshot.PNG" style="width:100%" class="rounded" alt="Super Survival Ball title screen" class="float-right">
        </div>
        <div class="column"></div>
    </div>
    <hr />
    <iframe frameborder="0" src="https://itch.io/embed-upload/2393590?color=333333" allowfullscreen="" width="800" height="620"><a href="https://quinn-patrick.itch.io/super-surivival-ball">Play Super Surivival Ball on itch.io</a></iframe>



<hr/>
<p>Super Survival Ball is a game that I made in 48 hours for Ludum Dare 46.</p>
<p>You can see the original competition entry page <a href="https://ldjam.com/events/ludum-dare/46/super-survival-ball">here.</a></p>
<br/>
        

