
    <h1>Chryse</h1>
    <div class="row">
        <div class="col-sm-8">
            <h3>
                When a force of darkness threatens the land, four youths are swept into the fight of their lives. 
                <br/><br/>
                But they have a long way to go before they're strong enough to win!
                <br/><br/>
                Battle monsters, collect treasure, and gain powerful fighting techniques in this epic fantasy role-playing experience!
            </h3>
        </div>
        <div class="col-sm-4">
            <img src="../assets/chryseCapture.PNG" style="width:100%" class="rounded" alt="Chryse gameplay capture" class="float-right">
        </div>
    </div>
    <hr/>
    <iframe frameborder="0" src="https://itch.io/embed/864976" width="552" height="167"><a href="https://quinn-patrick.itch.io/chryse">Chryse by Quinn_Patrick</a></iframe>
    <p>Chryse is a fantasy roleplaying game for which I decided to release what I had a couple years ago. I used the code to create Vespadian, which is much better.</p>
    <hr/>
    <h2>Mechanics</h2>
    <p>Players of Final Fantasy and Dragon Quest will find a lot of familiar ideas here, but with my own twists on them!</p>
    <h3>Battle</h3>
    <p>In some locations battle can break out at any time, while in others foes await on specific spots waiting for you to put a foot wrong.</p>
    <p>When in battle, every combatant takes turns based on their speed stat. Characters with a high speed stat may be able to act two or three times while a slow character acts only once!</p>
    <p>To be victorious in battle, you will have to consider your party formation as well! Each side of the battlefield is arranged in a four by four grid. Placing characters behind eachother can make them impossible to hit with certain attacks, but beware, your enemies can take advantage of this as well!</p>
    <p>When faced by such tactical opponents, break their formation by slaying their frontliners, or strike the back rows right away with magic!</p>
    <p>To the victor go the spoils! Upon defeating your enemies, you will be handsomely rewarded with treasure and experience!</p>
    <h3>Progression</h3>
    <p>As in life, gaining enough experience will grant you a new level!</p>
    <p>With each level, you can gain a new feature! Each of the four characters have their own tree of features which grant potent abilities and exotic powers.</p>
    <p>Many features will also boost one or more of that character's eight ability scores: strength, fortitude, dexterity, agility, intellect, wisdom, charisma, and spirit.</p>
    <p>Oftentimes, you will have the freedom to choose which ability score to boost. Those players who take the time to learn the ins and outs of each one will be able to build the party to their liking.</p>
    <hr/>
    <h2>Characters</h2>
    <p>The heroes of this tale are four (somewhat) intrepid young people, brought together by fate and perhaps blind luck. Let's take a moment to meet them.</p>
    <h3>Eric</h3>
    <p>Age: 14</p>
    <p>Specialties: Strength, Fortitude</p>
    <p>Special Powers: Combat stances, expertise with a variety of weapons and armor</p>
    <p>
        Eric is a knight (in training) from the city of El Trice in central Elta.
        He is driven by a keen sense of dedication to his friends, and his determination to not let them down.
        He isn't without fault, however. After all, courage and self sacrifice, the highest virtues of knighthood, are difficult for someone of such tender years.
    </p>
    <p>
        Tender or not, Eric will have to find the courage to face the battle ahead of him.
        As the voice of reason among his friends, it's up to him to keep them together and moving forward on their long, and difficult journey.
    </p>
    <h3>Frida</h3>
    <p>Age: 14</p>
    <p>Specialties: Intellect, Wisdom</p>
    <p>Spcial Powers: Divine Magic</p>
    <p>
        The journey will begin when Frida is displaced from her home by the nefarious Lord's Army.
        As the only daughter of the noble house Tricius of El Trice, she isn't accustomed to such hardship, and she'll be sure to let everyone know.
    </p>
    <p>
        Don't count her out based on her pampered personality, though! Frida is studious,
        and sharp as a tack. Being born gifted with magic doesn't hurt, either!
        That being said, don't you think that her priviledged upbringing will clash with some of the more
        salt-of-the-earth folk she'll be meeting quite soon? She may just have to broaden her point of
        view if everyone is to stay together!
    </p>
    <h3>Alamand</h3>
    <p>Age: 13</p>
    <p>Specialties: Charisma, Sprit</p>
    <p>Special Powers: Arcane Magic</p>
    <p>
        Alamand-his friends call him Al-was born in the far off land of Amharra. 
        When his wretchedly poor family discovered that he was gifted with magic, 
        they sent him to Elta in hopes that he could have a better life there.
        In Elta, he developed a theatrical personality and fierce independence, but with little memory of
        his homeland.
    </p>
    <p>
        Al has no issue with using his exotic appearence to take advantage of the Eltan locals,
        but between you and me, it's all just a character. He may feel like an alien sometimes, but inside
        he's every bit as Eltan as Frida and Eric. Despite that, he won't be the best of friends with
        them when they first meet, but he'll warm up to them for sure, won't he?
    </p>
    <h3>Mary</h3>
    <p>Age: 12</p>
    <p>Specialties: Dexterity, Agility</p>
    <p>Special Powers: Theivery and Stealth</p>
    <p>
        Bringing up the rear is the diminutive thieving street urchin with a heart of gold. Probably.
        Raised on the mean streets and rejecting human contact for much of her life, Mary has a lot to
        learn before she's ready to be a normal kid. But she still has an undeniable charm that will
        win over Eric and encourage him to invite her into the group, though Frida and Al may not
        see it right away.
    </p>
    <p>
        She's got more than cute looks, though. Afterall, you don't survive on the streets for years
        without a certain set of skills including outstanding acrobatics and a willingness to do what it
        takes to survive, even if those things may give more civilized folk pause.
    </p>
    <p>
        Further, while she may never have seen the inside of a book, she still has a certain streetwise
        intelligence that will surely help keep her newfound companions out of trouble.
    </p>

    <br/>

