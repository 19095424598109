import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vespadian',
  templateUrl: './vespadian.component.html',
  styleUrls: ['./vespadian.component.css']
})
export class VespadianComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
