<!-- Add icon library -->
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">




    <h2>About Me</h2>
    <p>Thanks for visiting my site!</p>
    <p>My name is Quinn Patrick and I'm a software engineer living in Pennsylvania.</p>
    <p>I've been making games since basically forever.
        After graduating college in 2019, I decided to make this website to share some of my favorites.
    My degree is actually in Physics. Go figure.</p>
    <p>I'm basically banking on somebody out there enjoying them. That would really make my day.</p>
    <p>I've played a lot of games, too! These are my top five:</p>
    <ol reversed>
        <li>Final Fantasy IV</li>
        <li>Pokemon Emerald</li>
        <li>Kingdom Hearts II</li>
        <li>The Legend of Zelda: A Link to the Past</li>
        <li>Cave Story</li>
    </ol>

    <p>Here's some social media you can follow me on:
        <a href="https://www.twitch.tv/quinn_patrick" class="fa fa-twitch"></a>
        <a href="https://twitter.com/Daisuke9091" class="fa fa-twitter"></a>
        <a href="https://www.youtube.com/channel/UCBAL8YiSPPd5zvhEPQ3tqyA?view_as=public" class="fa fa-youtube"></a>
    </p>




