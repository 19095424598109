
    <h1>Nephila</h1>
    <div class="row">
        <div class="col-sm-8">
            <h3>
                For as long as memory serves, a tower reaching all the way to heaven has served as the center of human civilization, its upper floors shrouded in mystery. But recently, legions of monsters have been unleashed upon the world, sent by Aeus, the lord of Pandemonium.
                 <br/><br/>
                 Now you must arm yourself, and find a way to reach him. 
                 <br/><br/>
                 Explore the world, battle monsters, find upgrades, and learn the ancient secrets.</h3>
                 <iframe frameborder="0" src="https://itch.io/embed/648241?linkback=true" width="552" height="167"><a href="https://quinn-patrick.itch.io/nephila">Nephila by Quinn_Patrick</a></iframe>
        </div>
        <div class="col-sm-4">
            <img src="../assets/nephilaCapture.PNG" style="width:100%" class="rounded" alt="Super Survival Ball title screen" class="float-right">
        </div>
    </div>
    <hr/>
    <p>Nephila is a metroidvania that I created between 2015 and 2018, starting when I was a senior in highschool!</p>
    <p>You play as a mysterious little hero who must explore a labrynthian world map and find a way to enter Pandemonium and defeat the final villain Aeus.</p>
    <p>So what does Nephila mean? Maybe you'll find out if you play!</p>
    <p>My current Twitter icon is the little blue enemy who appears right at the start of the game. I wish I had thought up a name for him...</p>
    <br/>

