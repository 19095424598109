<nav class="navbar navbar-default">
    <div class="container-fluid">
      <div class="navbar-header">
        <a class="navbar-brand" routerLink="">Quinn's Games</a>
      </div>
      <ul class="nav navbar-nav">
        <li><a routerLink="">Games</a></li>
        <li><a routerLink="about">About Me</a></li>
      </ul>
    </div>
  </nav>