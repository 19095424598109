<div class="content">
    <h1>Looping Condor 3000</h1>
        <div class = "row">
            <div class="col-sm-8">
                <h3>
                    In the year 3000, time stands still.
                    <br/><br />
                    Loop your way through six challenging levels using only the arrow keys!
                    <br /><br />
                    Choose from three high performance aircraft!
                    <br /><br />
                    Featuring cyclical gameplay for limitless challenge!
                </h3>
            </div>
            <div class="col-sm-4">  
                <img src="../assets/loopingCondorCapture.png" style="width:100%" class="rounded" alt="looping condor 3000 title screen" class="float-right">
            </div>
            <div class="column"></div>
        </div>



    <hr/>
    <iframe frameborder="0" src="https://itch.io/embed-upload/2951871?color=333333" allowfullscreen="" width="1024" height="788"><a href="https://quinn-patrick.itch.io/looping-condor-3000">Play Looping Condor 3000 on itch.io</a></iframe>
    <iframe frameborder="0" src="https://itch.io/embed/777672" width="552" height="167"><a href="https://quinn-patrick.itch.io/looping-condor-3000">Looping Condor 3000 by Quinn_Patrick</a></iframe>
    <p>Looping Condor 3000 was created in 48 hours for Ludum Dare 47. The theme was Stuck in a Loop. It captured 85th in fun with a score of 3.851 out of 5.</p>
    <p>You can play it with the arrow keys only. On the title screen, double tap left to change your ship and double tap right to start the game.</p>
    <p>You can go for the best time through the six levels. If you complete those six levels, the game will start over but things will be harder, but your score multiplier will be higher, so you can go for the high score.</p>
    <br/>
</div>
