<div class="content">
    <h1>Looping Condor 3000</h1>
        <div class = "row">
            <div class="col-sm-8">
                <h3>
                    Time flies when you're having fun!
                    
                </h3>
            </div>
            <div class="col-sm-4">  
                <img src="../assets/timeflightCover.png" style="width:100%" class="rounded" alt="Timeflight Alpha cover art" class="float-right">
            </div>
            <div class="column"></div>
        </div>



    <hr/>
    <iframe frameborder="0" src="https://itch.io/embed-upload/5545257?color=333333" allowfullscreen="" width="1232" height="728"><a href="https://quinn-patrick.itch.io/timeflight-alpha">Play Timeflight Alpha on itch.io</a></iframe>
    <iframe frameborder="0" src="https://itch.io/embed/1469911" width="552" height="167"><a href="https://quinn-patrick.itch.io/timeflight-alpha">Timeflight Alpha by Quinn_Patrick</a></iframe>
    <p>Timeflight Alpha was created in 48 hours for Ludum Dare 50. The theme was "delay the inevitable." It placed 102nd in the fun category, with a score of 3.804 out of 5.</p>
    <p>Controls:</p>
    <p>Arrow Keys: Move</p>
    <p>Hold Z: Shoot</p>
    <p>Hold Shift: Dash</p>
    <p>The object of the game is to blast everything, advance to higher levels, and score points.</p>
    <p>To do this, you must collect the items dropped by the asteroids you shoot.</p>
    <p>The item that flashes "GET IT" is required. There is one per level, and only one asteroid has it. Once you get it, you will advance to the next level when time runs out, but only if you are in the base.</p>
    <p>The spinning hourglass adds more time to the clock. The later the level you are in and the longer you have been in that level, the less time it will add.</p>
    <p>The upward arrow item upgrades your gun for ten seconds. If your gun is already at level three, it resets the ten seconds.</p>
    <p>Here's the catch: you score bonus points when the level ends based on how long you delayed it for (by collecting hourglasses). It is inevitable that the level will end, but if you delay it for as long as possible, you will score massive points!</p>
    <p>If time runs out and you either are not in the base, or you don't have the "GET IT" item, the game is over.</p>
    <p>Try playing with headphones on!</p>
    <br/>
</div>
