import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-survival-ball',
  templateUrl: './survival-ball.component.html',
  styleUrls: ['./survival-ball.component.css']
})
export class SurvivalBallComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
