
    <h1>ecliptic 2</h1>


    <div class = "row">
        <div class="col-sm-8">
            <h3>
                The sequel to the hit orbital mechanics game is here! 
                <br/><br />
                Complete six more levels filled with diverse  challenges!
                <br /><br />
                Complete every mission and go for the high score!
            </h3>
        </div>
        <div class="col-sm-4">  
            <img src="../assets/ecliptic2logo.png" style="width:100%" class="rounded" alt="ecliptic 2 key image" class="float-right">
        </div>
        <div class="column"></div>
    </div>



<hr/>
<iframe frameborder="0" src="https://itch.io/embed-upload/5477245?color=333333" allowfullscreen="" width="1200" height="695"><a href="https://quinn-patrick.itch.io/ecliptic-2">Play ecliptic 2 on itch.io</a></iframe>
<iframe frameborder="0" src="https://itch.io/embed/1450907" width="552" height="167"><a href="https://quinn-patrick.itch.io/ecliptic-2">ecliptic 2 by Quinn_Patrick</a></iframe>
<p>ecliptic 2 is the sequel to ecliptic, my 2D orbital mechanics game. Unlike the original, ecliptic 2 features planets you can land on and multiple mission types.</p>
<p>Use the left and right arrows to rotate your ship, and use the up arrow to thrust, but watch your fuel! Press 'Z' to shoot asteroids.</p>
<p>You can zoom out by pressing 'shift', or see the entire level by pressing 'esc'.</p>
<p>You can press 'R' to retry from the checkpoint at any time, though this will count as crashing your ship.</p>
<p>ecliptic 2 also supports analogue control with gamepads, although results may be vary.</p>
<br/>
        
