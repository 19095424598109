
    <h1>ecliptic</h1>


    <div class = "row">
        <div class="col-sm-8">
            <h3>
                Put on your thinking cap and master orbital dynamics in this physics puzzler. 
                <br/><br />
                Completing all six levels will take some serious skill!
                <br /><br />
                Click in the window below to play!
            </h3>
        </div>
        <div class="col-sm-4">  
            <img src="../assets/eclipticCapture.png" style="width:100%" class="rounded" alt="ecliptic title screen" class="float-right">
        </div>
        <div class="column"></div>
    </div>



<hr/>
<iframe frameborder="0" src="https://itch.io/embed-upload/2316005?color=333333" allowfullscreen="" width="800" height="600"><a href="https://quinn-patrick.itch.io/ecliptic">Play ecliptic on itch.io</a></iframe>
<iframe frameborder="0" src="https://itch.io/embed/657992" width="552" height="167"><a href="https://quinn-patrick.itch.io/ecliptic">ecliptic by Quinn_Patrick</a></iframe>
<p>ecliptic is a side project that I created in about a month around April-May 2020.</p>
<p>It gained some popularity after I posted it to the subreddit r/webgames, and it is nearing one thousand plays on itch.io!</p>
<p>ecliptic utilizes realistic Keplarian orbital motion. All of the levels in the game only support 2 body dynamics, but the code supports n-body dynamics. 
    That is to say, if I added another sun to one of the levels, both the suns would attract eachother.
    I decided not to make any levels utilizing this because they would quickly become chaotic and unpredictable. I found the single-sun levels to be much more fun.</p>
<br/>
        
