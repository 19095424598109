<div class="content">
    <h1>Brubnar the Barbarian and the Deepest Dungeon</h1>
        <div class = "row">
            <div class="col-sm-8">
                <h3>
                    ​Play through endless floors of escalating challenge as Brubnar the Barbarian!
                    <br/><br />
                    Get deep with the mystical Wordes of Wisdome!
                    <br /><br />
                    Rack up gold and spend it on powerful upgrades and magic spells!
                </h3>
            </div>
            <div class="col-sm-4">  
                <img src="../assets/brubnarCapture.png" style="width:100%" class="rounded" alt="Brubnar the Barbarian title screen" class="float-right">
            </div>
            <div class="column"></div>
        </div>



    <hr/>
    <iframe frameborder="0" src="https://itch.io/embed-upload/3744750?color=333333" allowfullscreen="" width="800" height="620"><a href="https://quinn-patrick.itch.io/brubnar-the-barbarian-and-the-deepest-dungeon">Play Brubnar the Barbarian in the Deepest Dungeon on itch.io</a></iframe>
    <iframe frameborder="0" src="https://itch.io/embed/1013253" width="552" height="167"><a href="https://quinn-patrick.itch.io/brubnar-the-barbarian-and-the-deepest-dungeon">Brubnar the Barbarian in the Deepest Dungeon by Quinn_Patrick</a></iframe>
    <p>The game is supposed to start when you press any key, but if it doesn't work try the "Play on itch.io" link.</p>
    <p>Brubnar the Barbarian and the Deepest Dungeon was created in 48 hours for Ludum Dare 48. The theme was Deeper and Deeper. It placed 69th in the humor category, my best ever category placement in Ludum Dare.</p>
    <p>You swing the sword with shift and cast a spell will spacebar. Spacebar will also skip the cutscene at the start, so be careful! Also keep in mind that you can only have one spell at a time, buying a new one will replace the old one.</p>
    <p>The objective is play for a high score. The game is arcade style, and attempts can go quite long!</p>
    <p>This game is primarily known for the "Wordes of Wisdome," a random deep quote generator which is sometimes funny. All it does, though, is jam together a string from one list with a string from another.</p>
    <br/>
</div>
