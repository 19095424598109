
    <h1>Vespadian</h1>
    <div class="row">
        <div class="col-sm-8">
            <h3>
                Remember that no matter what happens out there, you can never go back.
                 </h3>
                 <iframe frameborder="0" src="https://itch.io/embed/1173798" width="552" height="167"><a href="https://quinn-patrick.itch.io/vespadian">Vespadian by Quinn_Patrick</a></iframe>
        </div>
        <div class="col-sm-4">
            <img src="../assets/screenshot2.PNG" style="width:100%" class="rounded" alt="Vespadian Key Art" class="float-right">
        </div>
    </div>
    <hr/>
    <p>Vespadian is a Final Fantasy-style RPG which I created throughout 2021 using the code of Chryse as a base. It plays rather differently from Chryse, and features a slew of improvements.</p>
    <p>At the heart of Vespadian is the highly customizable party-building system. At the beginning of the game, you will be able to create your own party of four adventurers from any combination of the six character classes currently available. You can also customize your party cosmetically and name them whatever you like, so by all means, get creative and use your imagination!</p>
    <p>Once you gain a few levels, you may consider multiclassing. Characters of at least level 10 can choose to begin gaining levels as a different class instead. After gaining ten levels in their new class, they may switch again. For now, the overall max level is 50, but you can mix and match those 50 levels for each character to create something truly unique. In the future, I plan to raise the max level to 99 while keeping the max level for a single class at 50, thus making the selection of a second class necessary for any character to unlock his or her full potential.</p>
    <p>Vespadian has less of a focus on story than Chryse. This is to leave more room for you to i
    <h3>Classes</h3>
    <p>Vespadian has six character classes to choose from right now, with more on the way.</p>
    <ul>
    <li>
    <h4>Fighter</h4>
    <ul>
    <li>
    The Fighter is the strongest class, physically speaking. They have higher strength and hit points than any other class, but they are also relatively slow. They gain the ability to attack multiple times in one turn, and are the only class able to equip heavy armor and two-handed weapons.
    </li>
    </ul>
    </li>
    <li>
    <h4>Wizard</h4>
    <ul>
    <li>
    The Wizard is the most intelligent class, but also the most physically weak, having very little strength and hit points. Their main selling point is their mastery of arcane magic, allowing them to cast the most powerful spells, as long as they learn them from a spell tome first. They gain the ability to augment the power of certain spells, and the greatest Wizards can even cast a spell two times for the cost of one.
    </li>
    </ul>
    </li>
    <li>
    <h4>Thief</h4>
    <ul>
    <li>
    The Thief is fastest class, and they have the unique ability to steal items from monsters, allowing them to easily acquire some items that are very difficult to obtain otherwise. They can also pick locks as long as their level as a Thief is high enough, saving you the effort of finding the key. It is important to keep in mind, however, that the Thief has few attributes aside from their speed that help them in battle.
    </li>
    </ul>
    </li>
    <li>
    <h4>Cleric</h4>
    <ul>
    <li>
    The Cleric is a holy warrior who casts divine spells that they learn innately through gaining experience. These spells are focused on support, doing things like restoring hit points and reducing the amount of damage that party members suffer. They may also become immune to certain status ailments. The Divine Magic that Clerics use is indispensable to any adventuring party, but they are also fairly slow and struggle to inflict damage. Overall, choosing not to bring a Cleric will make your journey much harder.
    </li>
    </ul>
    </li>
    <li>
    <h4>Ranger</h4>
    <ul>
    <li>
    The Ranger is a highly nimble and dexterous class that focuses on advanced fighting techniques and exploration. They are the only class able to equip a weapon in both hands, and they can make attacks at high speed, allowing them to attack again sooner. They can also spot enemy ambushes before they occur, allowing you to avoid them. Ultimately, they gain the ability to avoid all random encounters.
    </li>
    </ul>
    </li>
    <li>
    <h4>Bard</h4>
    <ul>
    <li>
    The Bard is a smooth-talking spellcaster able to learn both arcane and divine magic, although neither to as advanced a level as the Wizard or Cleric respectively. They gain the ability to add their Charisma to the power of spells that they cast, as well as add additional effects to support spells. The greatest Bards can talk their way out of anything by gaining a Charisma-based bonus to all of their stats.
    </li>
    </ul>
    </li>
    </ul>
    <br/>

